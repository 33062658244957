/* eslint-disable no-useless-escape */
import { constructPhoneNumber } from "best-common-react";

/**
 * turn a string into titleCase
 * @param value the string to change
 * @returns a title-cased string
 */
export const titleCase = (value: string) => {
  return value.split("s+").map(token => token.charAt(0).toUpperCase() + token.slice(1).toLowerCase());
};

/**
 * check if an email is a valid email (RFC)
 * @param email email to test
 * @returns true if the email is valid
 */
export const isEmailValid = (email: string) => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

/**
 * check if an url is a valid url
 * @param url url to test
 * @returns true if the url is valid
 */
export const isUrlValid = (url: string) => {
  const regex =
    /^(https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/i;
  return regex.test(url) && !url.endsWith(".") && !/.*\.{2,}.*/.test(url);
};

/**
 * Given a nonformatted phone number
 * return it with parentheses
 *
 * ex.) 1234567890 -> (123) 456-7890
 * */
export const formatPhoneNumber = (phoneNumber?: string): string => {
  if (phoneNumber) {
    const formattedNumber = constructPhoneNumber(phoneNumber);
    if (formattedNumber) {
      return formattedNumber.formatNational();
    }
  }
  return "";
};
