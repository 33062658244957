"use client";

import { UserApi } from "@/api/UserApi";
import { SAML_URL } from "@/constants";
import { useUser } from "@/contexts/UserContext";
import { useForm } from "@/hooks/useForm";
import { isEmailValid } from "@/utils/string";
import { Alert, Button, Email, FormColumn, isEmpty, Modal, Password } from "best-common-react";
import React, { FormEvent, useCallback, useState } from "react";
import styled from "styled-components";

interface LoginForm {
  email: string;
  password: string;
}

const defaultLoginForm: LoginForm = {
  email: "",
  password: ""
};

const Container = styled.section`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginBox = styled.div`
  width: 100%;
  max-width: 500px;
  border: 1px solid gray;

  @media screen and (max-width: 768px) {
    maring: 0 16px;
  }
`;

const LoginHeader = styled.div`
  background-color: #041e42;
  border-bottom: 1px solid gray;
  padding: 40px;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 1.625rem;
  letter-spacing: 1.5px;
`;

const LoginBody = styled.div`
  padding: 30px;
`;

/**
 * uses a raw button. buttons with type="submit" cannot have an onClick handler
 */
const LoginButton = styled.button`
  width: 100%;
  font-weight: 600;
  font-size: 0.875rem;
  padding: 0.3125rem 0.75rem;
`;

const OktaSpan = styled.span`
  color: rgb(137, 137, 137);
  font-weight: bold;
  font-size: 1.25rem;
`;

const PasswordSpan = styled(OktaSpan)`
  float: right;
`;

const Anchor = styled.a`
  color: #4c8cee;
  font-size: 0.875rem;
  text-decoration: none;
  border-bottom: none;
  vertical-align: baseline;
`;

interface LoginModalProps {
  open: boolean;
  onClose: () => void;
}

const ForgotPasswordModal = ({ open, onClose }: LoginModalProps) => {
  // hooks
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  // functions
  const onForgotPassword = async (e: React.MouseEvent | undefined, email: string) => {
    if (e) {
      e.preventDefault();
    }
    try {
      setLoading(true);
      await UserApi.forgotPassword(email);
      onClose();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={open}>
      <Modal.Header close={onClose}>Forgot password</Modal.Header>
      <Modal.Body>
        <Email required id="email" placeholder="Enter email" value={email} onChange={value => setEmail(value)} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" disabled={!isEmailValid(email) || loading} onClick={e => onForgotPassword(e, email)}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Login = () => {
  // hooks
  const { dispatch } = useUser();
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const { values, handleChange, isValid } = useForm(defaultLoginForm, {
    onValidate: values => isEmailValid(values.email) && !isEmpty(values.password)
  });

  // functions
  const onCloseForgetPassword = () => setShowForgotPassword(false);

  const showForgotPasswordModal = (e: React.MouseEvent<HTMLButtonElement> | undefined, value: boolean) => {
    // Prevent form submission
    if (e) e.preventDefault();

    setShowForgotPassword(value);
  };

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      // prevent page reload
      e.preventDefault();

      try {
        setError("");
        setSubmitting(true);
        // login
        await UserApi.loginWithCredentials(values.email, values.password);

        // if successful, get user data
        const userDetails = await UserApi.getCurrentUser();

        // once you've gotten user data, the `dispatch will handle re-routing
        // to the home page. in the future, we can provide a link here for
        // custom redirection
        dispatch({ type: "setUserDetails", userDetails });
      } catch (e) {
        console.error(e);
        setError("Make sure username and password are correct");
      } finally {
        setSubmitting(false);
      }
    },
    [dispatch, values]
  );

  return (
    <Container>
      <LoginBox>
        <LoginHeader>MLB Select</LoginHeader>
        <LoginBody>
          <form onSubmit={onSubmit}>
            <fieldset disabled={submitting}>
              <Email
                id="select-email"
                label="Email"
                required
                disabled={submitting}
                name="email"
                placeholder="Enter email"
                value={values.email}
                onChange={value => handleChange("email", value)}
                gutterBottom
              />
              <Password
                id="select-password"
                label="password"
                required
                disabled={submitting}
                name="password"
                placeholder="Password"
                value={values.password}
                onChange={value => handleChange("password", value)}
                gutterBottom
              />
              {!!error && (
                <Alert id="sign-in-error" type="danger">
                  {error}
                </Alert>
              )}
              <LoginButton type="submit" className="btn btn-success" disabled={!isValid}>
                Sign In
              </LoginButton>
              <div className="row">
                <FormColumn width={2}>
                  <OktaSpan>
                    MLB user?
                    <Anchor className="btn btn-link" href={SAML_URL}>
                      Login here
                    </Anchor>
                  </OktaSpan>
                </FormColumn>
                <FormColumn width={2}>
                  <PasswordSpan>
                    <Button type="button" variant="link" onClick={e => showForgotPasswordModal(e, true)}>
                      Forgot password?
                    </Button>
                  </PasswordSpan>
                </FormColumn>
              </div>
            </fieldset>
          </form>
          <ForgotPasswordModal open={showForgotPassword} onClose={onCloseForgetPassword} />
        </LoginBody>
      </LoginBox>
    </Container>
  );
};

export default Login;
