import React from "react";
import { useTheme } from "../../hooks";
import { IconThemeType } from "../../types";
import Icon, { IconProps } from "./Icon";

const IconAdorner: React.FC<React.PropsWithChildren<IconProps>> = ({ styles = {}, ...rest }) => {
  const { Theme } = useTheme();
  const StylesOverride: IconThemeType = { ...Theme.iconAdorner, ...styles };
  return <Icon {...rest} styles={StylesOverride} />;
};

export default IconAdorner;
