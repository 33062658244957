"use client";

import { areObjectsEqual } from "best-common-react";
import { SetStateAction, useCallback, useEffect, useRef, useState } from "react";

type ErrorMap<T extends object> = {
  [K in keyof T]?: (value: T[K]) => string | boolean;
};

interface FormOptions<T extends object> {
  onValidate?: ((values: T) => boolean) | ErrorMap<T>;
}

interface SetValueOptions {
  reinitialize?: boolean;
}

/**
 * This is a workaround until we can get formik working nicely
 * with BCR components. This will generate factory form functions
 * for you, as well as form variables.
 *
 * @param form an object that carries the default values for a form
 * @param options formFunctions
 * @returns info
 */
export function useForm<T extends object>(form: T, options: FormOptions<T> = {}) {
  // instance
  const { onValidate } = options;

  // hooks
  const ref = useRef(form);
  const validateRef = useRef(onValidate);
  const [values, setValues] = useState(form);
  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState<ErrorMap<T>>({});

  // functions
  const handleChange = useCallback(function <U>(key: keyof T, value: U) {
    setValues(prev => ({ ...prev, [key]: value }));
  }, []);

  const setValuesWithOptions = useCallback((value: SetStateAction<T>, options: SetValueOptions = {}) => {
    // instance
    const { reinitialize = false } = options;

    // update values
    setValues(prev => {
      const result = typeof value === "function" ? value(prev) : value;

      if (reinitialize) {
        ref.current = result;
      }
      return result;
    });
  }, []);

  const resetForm = useCallback(() => {
    setValues(ref.current);
  }, []);

  // effects
  // update validate function ref
  useEffect(() => {
    validateRef.current = onValidate;
  }, [onValidate]);

  // handle validity
  useEffect(() => {
    const validate = validateRef.current;
    if (validate) {
      if (typeof validate === "function") {
        setErrors({});
        setIsValid(validate(values));
      } else {
        const newErrors = Object.keys(validate).reduce((pv, k) => {
          const key = k as keyof T;
          const func = validate[key];
          if (!func) {
            return pv;
          }

          const result = func(values[key]);
          if (!result) {
            return pv;
          }

          return { ...pv, [key]: result };
        }, {} as ErrorMap<T>);

        setErrors(newErrors);
        setIsValid(!Object.keys(newErrors).length);
      }
    }
  }, [values]);

  // handle dirty check
  useEffect(() => {
    setIsDirty(!areObjectsEqual(values, ref.current));
  }, [values]);

  return { values, errors, isValid, isDirty, handleChange, resetForm, setValues: setValuesWithOptions };
}
