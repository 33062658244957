import React, { useState } from "react";
import IconAdorner from "../../../components/icon/IconAdorner";
import Input, { InputProps } from "../input/Input";

const Password: React.FC<React.PropsWithChildren<InputProps>> = ({ disabled, ...rest }) => {
  const [type, setType] = useState<"text" | "password">("password");

  const changeType = () => {
    if (type === "text") {
      setType("password");
    } else {
      setType("text");
    }
  };

  return (
    <Input
      {...rest}
      endAdorner={<IconAdorner iconName="fa-eye" onClick={changeType} />}
      type={type}
      disabled={disabled}
    />
  );
};

export default Password;
